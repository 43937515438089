import { IonModal, IonPage, isPlatform } from "@ionic/react"
import React, { useCallback, useRef, useState, useEffect } from "react"
import { NativeAudio } from "@awesome-cordova-plugins/native-audio"
import { Haptics } from "@capacitor/haptics"
import { KeepAwake } from "@capacitor-community/keep-awake"

import { useLocalStorageState } from "@ericbf/helpers/useLocalStorageState"
import { useLocalStorageStateRef } from "@ericbf/helpers/useLocalStorageStateRef"
import { join } from "@ericbf/helpers/join"

import { Settings } from "../settings"

/** An array containing a set of tuples of a timeout delay and vibration duration. This pattern matches the Wah wah wah wah of the sound effect that plays when time runs out. Each delay matches up with the beginning of a wah, and each duration matches up with the length of that wah. I grabbed these values from audacity. The result is that the phone with vibrate in sync with the sound.  */
const vibrations: [number, number][] = [
	[0, 330],
	[400, 349],
	[801, 348],
	[1202, 349],
	[1603, 1433]
]

/** This is a vibration pattern created from the vibrations array. It just changes the format to match that of `navigator.vibrate`, which expects durations of vibration and non-vibration. It matches up with the Wah wah wah wah sound, just as the vibrations array does. */
const pattern = vibrations.reduce<number[]>((trans, [delay, duration], index, array) => {
	trans.push(duration)

	if (index < array.length - 1) {
		trans.push(array[index + 1]![0] - delay - duration)
	}

	return trans
}, [])
const vibrationTimeouts: number[] = []

export type State = "running" | "paused" | "stopped"
export type CountdownStyle = "voice" | "tones" | "none"

const initialState = "stopped"

const defaultDuration = 60
const defaultVibrationEnabled = true
const defaultSoundEnabled = true
const defaultCountdownStyle = "voice"
const defaultEndSoundEnabled = true

const player = isPlatform("capacitor") ? undefined : document.createElement("audio")

if (!isPlatform("capacitor")) {
	player!.appendChild(document.createElement("source"))

	document.body.appendChild(player!)
}

function bufferToBase64(buffer: ArrayBuffer) {
	let binary = ""
	const bytes = new Uint8Array(buffer)
	const len = bytes.byteLength

	for (let i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]!)
	}

	return window.btoa(binary)
}

const preloads = [
	...Array.from({ length: 10 }, (_, i) => `${i + 1}`),
	"15",
	"30",
	"60",
	"300",
	"tone",
	"restart",
	"start",
	"wah wah"
].reduce<Record<string, Promise<string>>>((trans, name) => {
	trans[name] = isPlatform("capacitor")
		? NativeAudio.preloadComplex(`${name}`, `../public/audio/${name}.mp3`, 1, 1, 0)
				.then((value) => {
					// console.log(`Successfully loaded ${name}`, value)

					return value
				})
				.catch((error) => console.error(`Failed to load ${name}`, error))
		: new Promise<string>((resolve) => {
				const request = new XMLHttpRequest()

				request.open("GET", `audio/${name}.mp3`, true)
				request.responseType = "arraybuffer"
				request.addEventListener(
					"load",
					() => {
						const data = bufferToBase64(request.response)

						resolve(`data:audio/mpeg;base64,${data}`)
					},
					false
				)

				request.send()
		  }).then((data) => data)

	return trans
}, {})

let timeStopped = 0
let timeStarted = 0

export const Home: React.FC = () => {
	const [duration, setDuration] = useLocalStorageState("duration", defaultDuration)
	const [vibrationEnabled, setVibrationEnabled] = useLocalStorageState(
		"vibrationEnabled",
		defaultVibrationEnabled
	)
	const [soundEnabled, originalSetSoundEnabled] = useLocalStorageState(
		"soundEnabled",
		defaultSoundEnabled
	)
	const [countdownStyle, setCountdownStyle] = useLocalStorageState<CountdownStyle>(
		"countdownStyle",
		defaultCountdownStyle
	)
	const [endSoundEnabled, setEndSoundEnabled] = useLocalStorageState(
		"endSoundEnabled",
		defaultEndSoundEnabled
	)

	const [valueRef, setValue, valueState] = useLocalStorageStateRef(
		"value",
		defaultDuration
	)
	const [stateRef, originalSetState, stateState] = useLocalStorageStateRef<State>(
		"state",
		initialState
	)

	const previousAudio = useRef<string>()

	const play = useCallback(
		async (id: string | number) => {
			if (vibrationTimeouts.length > 0) {
				vibrationTimeouts.forEach(clearTimeout)
				vibrationTimeouts.length = 0
			}

			const name = `${id}`
			const data = await preloads[name]

			if (soundEnabled) {
				if (isPlatform("capacitor")) {
					if (previousAudio.current) {
						NativeAudio.stop(previousAudio.current)
					}

					NativeAudio.play(name)

					previousAudio.current = name
				} else if (data) {
					player!.src = data
					player!.currentTime = 0
					player!.play().catch(() => {})
				}
			}
		},
		[soundEnabled]
	)

	const setSoundEnabled = useCallback(
		(soundEnabled: boolean) => {
			originalSetSoundEnabled(soundEnabled)

			if (!soundEnabled) {
				if (isPlatform("capacitor")) {
					if (previousAudio.current) {
						NativeAudio.stop(previousAudio.current)
					}
				} else {
					player!.pause()
				}
			}
		},
		[originalSetSoundEnabled]
	)

	useEffect(() => {
		if (stateState === "running") {
			KeepAwake.keepAwake()
		} else {
			KeepAwake.allowSleep()
		}
	}, [stateState])

	const setState = useCallback(
		(state: State) => {
			if (state === "stopped") {
				timeStopped = performance.now()
			}

			originalSetState(state)
		},
		[originalSetState]
	)

	const timeEnded = useCallback(() => {
		setState("stopped")

		if (endSoundEnabled) {
			play("wah wah")
		}

		if (vibrationEnabled) {
			if (isPlatform("capacitor")) {
				for (const [delay, duration] of vibrations) {
					const timeout = setTimeout(() => {
						const index = vibrationTimeouts.indexOf(timeout)

						if (index >= 0) {
							vibrationTimeouts.splice(index, 1)
						}

						Haptics.vibrate({ duration })
					}, delay) as unknown as number

					vibrationTimeouts.push(timeout)
				}
			} else {
				navigator.vibrate?.(pattern)
			}
		}
	}, [endSoundEnabled, play, setState, vibrationEnabled])

	const animation = useRef(0)

	const start = useCallback(() => {
		cancelAnimationFrame(animation.current)

		animation.current = requestAnimationFrame(() => {
			const time = performance.now()

			if (stateRef.current === "running") {
				const elapsed = (time - timeStarted) / 1000

				const remaining = duration - elapsed
				const centisecond = Math.max(0, Math.round(remaining * 100) / 100)
				const second = Math.round(remaining)
				const offset = 0

				if (
					valueRef.current > second + offset &&
					centisecond <= second + offset &&
					`${second}` in preloads
				) {
					switch (countdownStyle) {
						case "voice":
							play(second)
							break
						case "tones":
							play("tone")
							break
						case "none":
							break
					}
				}

				if (centisecond === 0) {
					timeEnded()
				}

				setValue(centisecond)
				start()
			}
		})
	}, [countdownStyle, duration, play, setValue, stateRef, timeEnded, valueRef])

	const [showSettings, setShowSettings] = useState(false)

	const click = useCallback(() => {
		if (showSettings) {
			return
		}

		const { current: state } = stateRef

		if (
			state !== "stopped" ||
			valueRef.current > 0 ||
			timeStopped + 1500 < performance.now()
		) {
			if (state !== "paused") {
				timeStarted = performance.now()
			} else {
				timeStarted = performance.now() - (duration - valueRef.current) * 1000
			}

			if (state !== "running") {
				play("start")
				setState("running")
			} else if (state === "running") {
				play("restart")
			}

			start()
		}
	}, [duration, play, setState, showSettings, start, stateRef, valueRef])

	const stopPropagation = useCallback((event: React.PointerEvent) => {
		event.stopPropagation()
	}, [])

	const settingsClick = useCallback(
		(event: React.MouseEvent) => {
			if (stateRef.current === "running") {
				setState("paused")
			}

			setShowSettings(true)

			event.stopPropagation()
		},
		[setState, stateRef]
	)

	const rightButtonTap = useCallback(
		(event: React.MouseEvent) => {
			if (stateRef.current === "running") {
				setState("paused")
			} else {
				setState("stopped")
				setValue(duration)
			}

			event.stopPropagation()
		},
		[duration, setState, setValue, stateRef]
	)

	const minutes = Math.floor(valueState / 60)
	const seconds = Math.floor(valueState) % 60
	const millis = Math.round((valueState % 1) * 100)

	const valueString = `${minutes}:${`0${seconds}`.slice(-2)}:${`0${millis}`.slice(-2)}`

	const rightButtonIcon = React.useMemo(() => {
		if (stateState === "running") {
			return "icon-pause"
		}

		if (valueState === duration) {
			return undefined
		}

		return "icon-undo"
	}, [duration, stateState, valueState])

	const content = React.useMemo(
		() => (
			<>
				<span className="absolute bottom-[calc(50vh-0.04em)] right-1/2 translate-x-1/2 translate-y-1/2 text-[color:var(--foreground)] pointer-events-none font-mono text-[50px] sm:text-[64px] md:text-[80px] lg:text-[94px] xl:text-[128px]">
					{valueString}
				</span>
				<button
					className="bg-[color:var(--foreground)] text-[color:var(--background)] border-none rounded-full cursor-pointer absolute bottom-[max(env(safe-area-inset-bottom)+0.3em,1em)] left-[max(env(safe-area-inset-left)+0.4em,1em)] focus:outline-none h-[2.37em] w-[2.37em] text-[32px] sm:text-[36px] md:text-[40px] lg:text-[44px] xl:text-[48px]"
					onClick={settingsClick}
					onPointerDown={stopPropagation}
				>
					<i className="icon-cog absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
				</button>
				{rightButtonIcon && (
					<button
						className="bg-[color:var(--foreground)] text-[color:var(--background)] border-none rounded-full cursor-pointer absolute bottom-[max(env(safe-area-inset-bottom)+0.3em,1em)] right-[max(env(safe-area-inset-right)+0.4em,1em)] focus:outline-none h-[2.37em] w-[2.37em] text-[32px] sm:text-[36px] md:text-[40px] lg:text-[44px] xl:text-[48px]"
						onClick={rightButtonTap}
						onPointerDown={stopPropagation}
					>
						<i
							className={join(
								rightButtonIcon,
								"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
							)}
						/>
					</button>
				)}
			</>
		),
		[rightButtonIcon, rightButtonTap, settingsClick, stopPropagation, valueString]
	)

	const closeSettings = useCallback(() => setShowSettings(false), [])

	const updateDuration = useCallback(
		(newDuration: number) => {
			if (duration !== newDuration) {
				setDuration(newDuration)
				setValue(newDuration)
			}
		},
		[duration, setDuration, setValue]
	)

	return (
		<IonPage>
			<div
				className="h-full cursor-pointer [--foreground:black] [--background:white] bg-[color:var(--background)]"
				onPointerDown={stateState === "running" ? click : undefined}
				onClick={stateState !== "running" ? click : undefined}
			>
				{content}
				<div
					className="absolute bottom-0 left-0 right-0 [--foreground:white] [--background:black] bg-[color:var(--background)] overflow-hidden"
					style={{ height: `${(valueState / duration) * 100}%` }}
				>
					{content}
				</div>
			</div>
			<IonModal
				isOpen={showSettings}
				swipeToClose={true}
				onDidDismiss={closeSettings}
				breakpoints={[0, 1]}
				initialBreakpoint={1}
			>
				<Settings
					dismiss={closeSettings}
					duration={[duration, updateDuration]}
					vibrationEnabled={[vibrationEnabled, setVibrationEnabled]}
					soundEnabled={[soundEnabled, setSoundEnabled]}
					countdownStyle={[countdownStyle, setCountdownStyle]}
					endSoundEnabled={[endSoundEnabled, setEndSoundEnabled]}
				/>
			</IonModal>
		</IonPage>
	)
}
