"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filter = void 0;
var wrap_1 = require("./wrap");
/**
 * Filter out all falsy values from the passed array.
 *
 * @template Type The type of the elements in the array.
 * @param array The array (or single item) to filter.
 * @returns A version of the passed array with falsible items filtered out.
 */
function filter(array) {
    return wrap_1.wrap(array).filter(Boolean);
}
exports.filter = filter;
