import {
	IonPage,
	IonContent,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonList,
	IonItem,
	IonLabel,
	IonInput,
	isPlatform,
	IonIcon,
	IonToggle,
	IonSelect,
	IonSelectOption,
	IonItemDivider
} from "@ionic/react"
import { closeOutline } from "ionicons/icons"
import React, { useCallback, useState } from "react"

import { CountdownStyle } from "../home"

export type State<T> = [T, React.Dispatch<T>]

export type SettingsProps = {
	dismiss: () => void

	duration: State<number>
	vibrationEnabled: State<boolean>
	soundEnabled: State<boolean>
	countdownStyle: State<CountdownStyle>
	endSoundEnabled: State<boolean>
}

export const Settings: React.FC<SettingsProps> = ({
	dismiss,
	duration: [duration, setDuration],
	vibrationEnabled: [vibrationEnabled, setVibrationEnabled],
	soundEnabled: [soundEnabled, setSoundEnabled],
	endSoundEnabled: [endSoundEnabled, setEndSoundEnabled],
	countdownStyle: [countdownStyle, setCountdownStyle]
}) => {
	const [myDuration, setMyDuration] = useState(duration)
	const [myVibrationEnabled, setMyVibrationEnabled] = useState(vibrationEnabled)
	const [mySoundEnabled, setMySoundEnabled] = useState(soundEnabled)
	const [myCountdownStyle, setMyCountdownStyle] = useState(countdownStyle)
	const [myEndSoundEnabled, setMyEndSoundEnabled] = useState(endSoundEnabled)

	const applyAndDismiss = useCallback(() => {
		dismiss()
		setDuration(myDuration)
		setVibrationEnabled(myVibrationEnabled)
		setSoundEnabled(mySoundEnabled)
		setCountdownStyle(myCountdownStyle)
		setEndSoundEnabled(myEndSoundEnabled)
	}, [
		dismiss,
		myCountdownStyle,
		myDuration,
		myEndSoundEnabled,
		mySoundEnabled,
		myVibrationEnabled,
		setCountdownStyle,
		setDuration,
		setEndSoundEnabled,
		setSoundEnabled,
		setVibrationEnabled
	])

	return (
		<IonPage>
			<IonHeader translucent>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButton onClick={dismiss}>
							{isPlatform("ios") ? "Cancel" : <IonIcon icon={closeOutline} />}
						</IonButton>
					</IonButtons>
					<IonTitle>Settings</IonTitle>
					<IonButtons slot="end">
						<IonButton
							onClick={applyAndDismiss}
							disabled={isNaN(myDuration) || myDuration <= 0}
						>
							{isPlatform("ios") ? "Apply" : "Save"}
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonList>
					<IonItem>
						<IonLabel>How many seconds?</IonLabel>
						<IonInput
							type="number"
							value={myDuration}
							style={{ textAlign: "right" }}
							onIonChange={(e) => setMyDuration(parseFloat(e.detail.value!))}
						/>
					</IonItem>
					{(isPlatform("capacitor") || !isPlatform("ios")) && (
						<IonItem>
							<IonLabel>Vibration enabled</IonLabel>
							<IonToggle
								checked={myVibrationEnabled}
								onIonChange={(e) => setMyVibrationEnabled(e.detail.checked)}
							/>
						</IonItem>
					)}
					<IonItem>
						<IonLabel>Sounds enabled</IonLabel>
						<IonToggle
							checked={mySoundEnabled}
							onIonChange={(e) => setMySoundEnabled(e.detail.checked)}
						/>
					</IonItem>
					{mySoundEnabled && (
						<>
							<IonItem>
								<IonLabel>Countdown style</IonLabel>
								<IonSelect
									value={myCountdownStyle}
									placeholder="Select One"
									onIonChange={(e) => setMyCountdownStyle(e.detail.value)}
								>
									<IonSelectOption value="voice">Voice</IonSelectOption>
									<IonSelectOption value="tones">Tones</IonSelectOption>
									<IonSelectOption value="none">None</IonSelectOption>
								</IonSelect>
							</IonItem>
							<IonItem>
								<IonLabel>Out of time sound enabled</IonLabel>
								<IonToggle
									checked={myEndSoundEnabled}
									onIonChange={(e) => setMyEndSoundEnabled(e.detail.checked)}
								/>
							</IonItem>
						</>
					)}
					<IonItemDivider>
						<IonLabel>Coming soon...</IonLabel>
					</IonItemDivider>
					<IonItem disabled>
						<IonLabel>Language</IonLabel>
						<IonSelect value="english" placeholder="Select One">
							<IonSelectOption value="english">English</IonSelectOption>
						</IonSelect>
					</IonItem>
					<IonItem detail disabled>
						<IonLabel>Support us (remove ads)</IonLabel>
					</IonItem>
					<IonItem detail disabled>
						<IonLabel>Help</IonLabel>
					</IonItem>
					<IonItem disabled>
						<IonLabel>Run in background</IonLabel>
						<IonToggle checked={false} />
					</IonItem>
					<IonItem disabled>
						<IonLabel>Pause when opening settings</IonLabel>
						<IonToggle checked />
					</IonItem>
				</IonList>
			</IonContent>
		</IonPage>
	)
}
