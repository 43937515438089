"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flatfilter = void 0;
var filter_1 = require("./filter");
var flat_1 = require("./flat");
/**
 * Completely flatten a nestable array, then filter out falsey values.
 *
 * @template Type The type of the elements in the array.
 * @param array The array to completely flatten, then from which to filter out falsey values.
 * @returns A flattened version of the passed array with falsible items filtered out.
 */
function flatfilter(array) {
    return filter_1.filter(flat_1.flat(array));
}
exports.flatfilter = flatfilter;
