"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.join = void 0;
var flatfilter_1 = require("./flatfilter");
function join(options) {
    var potentials = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        potentials[_i - 1] = arguments[_i];
    }
    var optionsWerePassed = Boolean(Boolean(options) && typeof options === "object" && !Array.isArray(options));
    var _a = (optionsWerePassed ? options : {}).separator, separator = _a === void 0 ? " " : _a;
    if (!optionsWerePassed) {
        potentials.unshift(options);
    }
    var values = flatfilter_1.flatfilter(potentials);
    return values.length > 0 ? values.reduce(function (trans, next) { return trans + separator + next; }) : "";
}
exports.join = join;
