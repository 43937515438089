"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flat = void 0;
var wrap_1 = require("./wrap");
/**
 * Completely flatten a nestable array.
 *
 * @template Type The type of the elements in the array.
 * @param array The array to completely flatten.
 * @returns A flattened version of the passed array.
 */
function flat(array) {
    return wrap_1.wrap(array).flat(Infinity);
}
exports.flat = flat;
